<template>
  <div style="padding:56.25% 0 0 0;position:relative;">
    <iframe
        :src="source"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen
        style="position:absolute;top:0;left:0;width:100%;height:100%;"
        :title="title"
    >
    </iframe>
  </div>
</template>

<script>

export default {
  name: "Vimeo",
  props: {
    vimeoId: null,
    title: null
  },
  data() {
    return {

    }
  },
  computed: {
    source(){
      return 'https://player.vimeo.com/video/' + this.vimeoId + '?h=1d1d5e8f15&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479';
    }
  }
}
</script>


